import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Destination from "./Components/Destination";

function App() {
  return (
    <Fragment>
      <Router>
        <div>
          <ul>
            <li>
              <Link to="/mars">Mars</Link>
            </li>
            <li>
              <Link to="/moon">Moon</Link>
            </li>
          </ul>
        </div>

        <Switch>
          <Route path="/:type">
            <Destination />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
